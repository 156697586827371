<template>
  <div v-if="items.length > 0">
    <v-autocomplete
      v-model="select"
      :loading="loading"
      :items="items"
      class="mx-4"
      flat
      hide-no-data
      hide-details
      color="#123123"
      style="width: 300px;background-color: none"
      item-text="Name"
      item-value="ID"
      @change="handleChangeWorkspace"
    />
  </div>
</template>
<script>
  import api from '@/services/api'
  import utils from '@/services/utils'
  import utilsBooking from '@/services/bookings'
  export default {
    data () {
      return {
        loading: false,
        items: [],
        select: null,
        environment: null,
      }
    },
    computed: {
    },
    watch: {
      '$route.meta.environment' (v) {
        this.environment =  this.$route.meta.environment
        if(v) this.handleGetWorkspaces()
      },
      '$store.state.app.playersWorkspace' (v) {
        if (v && this.select !== v) this.select = v 
      },
    },
    mounted () {
      this.environment =  this.$route.meta.environment
      this.handleGetWorkspaces()
    },
    methods: {
      handleGetWorkspaces () {
        //if (!this.environment || this.environment === 'status' || this.environment === 'status2'|| this.environment === 'players') {
        if (!this.environment || this.environment === 'status' || this.environment === 'status2') {
          this.items = []
          return
        }
        api.getAllWithoutLimit (this.environment, `v1/internal/users/${utils.getUser()}/workspaces`)
          .then(response => {
            this.items = response
            if (this.items.length === 0) {
              // si no tiene ningún workspace asociado lo enviamos a la página de error
              if ('noWorkspaces' !== this.$route.meta.mainPath) this.$router.push(`/${this.environment}/no-ws`)
              this.$store.state.app[`${this.$route.meta.environment}Workspace`] = null

              this.$vuetify.theme.themes.light.primary = process.env.VUE_APP_PRIMARYCOLOR
              this.$vuetify.theme.themes.light.secondary = process.env.VUE_APP_SECONDARYCOLOR
            } else {

              const aux = this.$store.state.app[`${this.environment}Workspace`] || utils.getWorkspace(this.environment)
              if (!aux || this.items.filter(x => x.ID === aux).length === 0) this.handleChangeWorkspace(this.items[0].ID)
              else this.handleChangeWorkspace(aux)
            }          
          })
      },
      handleChangeWorkspace (v) {
        if (v) {
          api.getItem (this.environment, `v1/internal/workspaces/`, v)
            .then(response => {
              if (!response) window.location.href = '/#/login'

              if (this.environment === 'dviewcontent') this.$store.state.app.timezone = response.Timezone
              if(this.environment === 'booking') utilsBooking.handleCheckRelocateReservations(this.environment, v)

              if (response.Languages) this.$store.state.app[`${this.environment}Languages`] = response.Languages.split(',')
              this.$store.state.app.bookingWorkspaceAllowReservation = response.AllowReservations ? true : false
              this.$store.state.app.bookingActtivAllowReservation = response.ActtivAllowReservation ? true : false
              this.$store.state.app.bookingWorkspaceData = {
                scheduleTypes: response.ScheduleTypes
              }
              // TODO
              if (response.token) api.setToken(response.token)
              
              this.select = v
              this.$vuetify.theme.themes.light.primary = response.PrimaryColor ? response.PrimaryColor.substring(0, 7) : process.env.VUE_APP_PRIMARYCOLOR
              this.$vuetify.theme.themes.light.secondary = response.SecondaryColor ? response.SecondaryColor.substring(0, 7) : process.env.VUE_APP_SECONDARYCOLOR

              this.$store.state.app[`${this.environment}Workspace`] = v
              utils.setWorkspace(this.environment, v)

            })
        }
      },
    },
  }
</script>
